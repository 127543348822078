.lesson {
	padding-top: 89px;
	&-head {
		display: flex;
		align-items: center;
		margin-bottom: 56px; }
	&-title {
		max-width: 400px;
		padding-right: 30px;
		flex-shrink: 0; }
	.section-subtitle {
		max-width: 625px; }
	&-body {
		background: #FFF;
		box-shadow: 0px 0px 30px rgba(166, 166, 166, 0.35);
		border-radius: 5px; }
	&-item {
		padding: 35px 43px;
		height: 100%;
		&-gray {
			background: #D5D7D6; } }
	&-utp {
		padding: 35px;
		&__title {
			font-family: Merriweather;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			color: #2E4878;
			margin-bottom: 35px; }
		&__item {
			display: flex;
			align-items: center;
			margin-bottom: 25px; }
		&__icon {
			margin-right: 15px; }
		&__text {
			font-weight: 300;
			font-size: 17px;
			color: #2E4878; } }
	&-form {
		text-align: center;
		&__img {
			margin-bottom: 15px; }
		.form-title {
			font-size: 22px; } }
	&-teacher {
		text-align: center;
		&__name {
			font-family: Merriweather;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 30px;
			color: #2E4878; }
		&__position {
			font-weight: 300;
			font-size: 17px;
			color: #2E4878;
			margin-bottom: 10px; }
		&__photo {
			margin-bottom: 20px;
			img {
				max-width: 100%; } }
		&__cta {
			font-weight: 300;
			font-size: 15px;
			color: #2E4878;
			display: flex;
			align-items: center;
			&::before {
				content: '';
				display: inline-block;
				width: 22px;
				height: 16px;
				background: url('../img/lesson/arrow.png') center no-repeat;
				background-size: contain;
				margin-right: 10px;
				flex-shrink: 0; } } } }
