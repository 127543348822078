.footer {
	padding-top: 126px;
	padding-bottom: 15px;
	background: #2D4889;
	margin-top: -67px;
	&-item {
		font-size: 16px;
		color: #FFF;
		margin-bottom: 15px;
		a {
			text-decoration: underline; } }
	&-small {
		font-size: 12px;
		font-weight: 300;
		margin-bottom: 0; } }
