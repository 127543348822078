.courses {
	padding-top: 64px;
	padding-bottom: 78px;
	background: #D5D7D6;
	&-title {
		margin-bottom: 57px; }
	&-content {
		position: relative; } }
.course {
	&-img {
		padding-top: 40px;
		height: 100%;
		display: flex;
		align-items: flex-end;
		img {
			max-width: 100%; } }
	&-content {
		padding-top: 61px; }
	&-title {
		font-family: Merriweather;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: #2E4878;
		margin-bottom: 38px; }
	&-price {
		display: flex;
		align-items: center;
		margin-bottom: 19px;
		&__result {
			margin-left: 40px;
			font-weight: 400;
			font-size: 24px;
			color: #2E4878;
			span {
				font-weight: 700; } } }
	&-links {
		display: flex;
		align-items: center;
		margin-bottom: 40px;
		a {
			margin-right: 40px; } }
	&-text {
		font-weight: 300;
		font-size: 17px;
		line-height: 25px;
		color: #2E4878;
		margin-bottom: 50px;
		ul {
			padding-left: 20px; }
		li {
			padding: 8px 0; } }
	&-buttons {
		.btn-red {
			padding-left: 26px;
			padding-right: 26px;
			margin-right: 19px; }
		.btn-transparent {
			padding-left: 45px;
			padding-right: 45px; } } }
