.system {
	padding-top: 80px;
	padding-bottom: 52px;
	.section-title {
		margin-bottom: 26px;
		max-width: 700px;
		margin-left: auto;
		margin-right: auto; }
	.section-subtitle {
		margin-bottom: 60px;
		max-width: 820px;
		margin-left: auto;
		margin-right: auto; }
	&-item {
		background: #2D4889;
		box-shadow: 0px 0px 30px rgba(166, 166, 166, 0.35);
		border-radius: 7px;
		color: #fff;
		text-align: center;
		padding: 32px 30px 36px;
		&__title {
			font-family: Merriweather;
			font-weight: bold;
			font-size: 22px;
			margin-bottom: 11px; }
		&__subtitle {
			font-weight: 300;
			font-size: 17px;
			margin-bottom: 12px; }
		&__img {
			margin-bottom: 16px;
			min-height: 165px;
			display: flex;
			align-items: center;
			justify-content: center; }
		&__button {
			.btn {
				padding-left: 52px;
				padding-right: 52px;
				&:hover {
					color: #fff;
					border: 2px solid #fff; } } } } }
