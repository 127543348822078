.method {
	padding-top: 76px;
	padding-bottom: 65px;
	.section-title {
		margin-bottom: 26px; }
	.section-subtitle {
		max-width: 800px;
		margin: 0 auto 20px; }
	&-graph {
		text-align: center;
		&__img {
			// margin-bottom: 31px
			img {
				max-width: 100%; } }
		&__text {
			font-weight: 300;
			font-size: 17px;
			color: #2E4878;
			margin: 0 auto 10px;
			max-width: 325px;
			span {
				font-weight: 500; } } }
	&-utp {
		background: #D5D7D6;
		box-shadow: 0px 0px 30px rgba(166, 166, 166, 0.35);
		border-radius: 7px;
		padding: 60px;
		margin-bottom: 55px;
		margin-top: 40px;
		&__item {
			&-icon {
				margin-bottom: 13px; }
			&-title {
				font-weight: 500;
				font-size: 17px;
				color: #2E4878; } } }
	&-button {
		margin-bottom: 20px;
		text-align: center;
		.btn {
			padding-left: 21px;
			padding-right: 21px; } }
	&-smalltext {
		font-weight: 300;
		font-size: 14px;
		text-align: center;
		color: #2E4878; } }
.mb-78 {
	margin-bottom: 78px; }
