.contacts {
	padding-top: 62px;
	.section-title {
		margin-bottom: 44px; }
	&-content {
		background: #FFF;
		box-shadow: 0px 0px 15px rgba(166, 166, 166, 0.25);
		border-radius: 5px;
		padding: 40px;
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 2; }
	&-items {
		padding-right: 20px;
		flex-shrink: 0; }
	&-item {
		display: flex;
		margin-bottom: 30px;
		&__icon {
			margin-right: 27px;
			min-width: 24px;
			text-align: center; }
		&__title {
			font-family: Merriweather;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			font-feature-settings: 'pnum' on, 'lnum' on;
			color: #2E4878;
			margin-bottom: 15px; }
		&__value {
			font-weight: 300;
			font-size: 17px;
			color: #2E4878;
			p {
				margin: 0;
				padding-top: 8px;
				padding-bottom: 8px; } }
		&__soc {
			a {
				margin-right: 15px; } } }
	&-map {
		width: 100%;
		// height: 597px
		img {
			max-width: 100%; } } }
