@import "bootstrap";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel.scss";
@import "../../node_modules/fancybox/dist/scss/jquery.fancybox.scss";
button, button:visited, button:focus, button:active, button:hover, select, select:visited, select:focus, select:active, select:hover, input, input:visited, input:focus, input:active, input:hover, textarea, textarea:visited, textarea:focus, textarea:active, textarea:hover area, map {
	outline: 0 none !important; }
button:hover, a:hover {
	cursor: pointer; }
ul {
	margin: 0;
	padding: 0; }
a {
	color: inherit;
	&:hover {
		color: inherit; } }
body {
	font-family: Roboto; }
@media(min-width: 1200px) {
	.container {
		max-width: 1200px; } }
@media(min-width: 1400px) {
	.container {
		max-width: 1360px; } }
.section-title {
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 50px;
	color: #2E4878;
	text-align: center; }
.section-subtitle {
	text-align: center;
	font-weight: normal;
	font-size: 20px;
	color: #2E4878; }
.cta {
	font-weight: 300;
	font-size: 14px;
	color: #2E4878;
	text-align: center; }
@import 'header';
@import 'mobile-menu';
@import 'footer';
@import 'form';
@import 'buttons';
@import 'tabs';
@import 'slider';
@import 'popup';
@import 'about';
@import 'banner';
@import 'contacts';
@import 'courses';
@import 'faq';
@import 'lesson';
@import 'method';
@import 'reviews';
@import 'system';
@import 'team';
@import 'utp';
@import 'media';
