@media(min-width: 1400px) {
	.d-xxl-none {
		display: none; } }
@media(max-width: 1500px) and (min-width: 1400px) {
	.slider-prev {
		left: 0; }
	.slider-next {
		right: 0; } }
@media(max-width: 1320px) and (min-width: 1200px) {
	.slider-prev {
		left: 0; }
	.slider-next {
		right: 0; } }
@media(max-width: 1060px) and (min-width: 992px) {
	.slider-prev {
		left: 0; }
	.slider-next {
		right: 0; } }
@media(max-width: 1399px) {
	.header {
		&-col {
			&-title {
				display: none; }
			&-loc {
				flex: 0 0 25%;
				max-width: 25%; } }
		&-title {
			font-size: 10px;
			margin-top: 5px;
			text-align: center; }
		&-button {
			.btn {
				font-size: 12px; } }
		&.scroll {
			transform: translateY(-82px); }
		&-nav {
			&__list {
				margin-left: -7px;
				margin-right: -7px; }
			&__item {
				margin: 0 7px;
				font-size: 12px; } }
		&-phone {
			font-size: 16px; } }
	.banner {
		&-left {
			width: 45.5%; }
		&-right {
			width: 54.5%; } }
	.quiz {
		&-title {
			font-size: 20px; }
		&-img {
			padding: 20px; } }
	.lesson {
		&-item {
			padding: 25px; }
		&-utp {
			&__text {
				font-size: 16px; } } }
	.course {
		&-links {
			a {
				margin-right: 25px; } } }
	.team {
		&-item {
			&-right {
				padding: 40px; } } }
	.achievement {
		&-num {
			font-size: 32px; }
		&-title {
			font-size: 12px; } }
	.method {
		&-utp {
			padding: 50px 30px; } } }
@media(max-width: 1199px) {
	.header {
		&-info {
			justify-content: flex-end; } }
	.banner {
		&-left {
			width: 50%;
			&-content {
				max-width: 480px;
				padding-bottom: 0; } }
		&-title {
			font-size: 30px;
			line-height: 45px; }
		&-right {
			width: 50%; }
		&-print {
			&::after {
				right: -45px; } } }
	.quiz {
		&-text {
			padding: 25px; }
		&-title {
			font-size: 18px; }
		&-subtitle {
			font-size: 15px;
			margin-bottom: 15px; } }
	.tab {
		font-size: 16px;
		padding: 10px; }
	.courses {
		&-title {
			margin-bottom: 29px; } }
	.course {
		&-content {
			padding-top: 31px; }
		&-title {
			margin-bottom: 17px; }
		&-text {
			margin-bottom: 20px; }
		&-links {
			margin-bottom: 20px; }
		&-img {
			height: 400px;
			padding: 20px 0;
			img {
				object-fit: cover;
				object-position: center;
				width: 100%;
				height: 100%; } } }
	.team {
		&-item {
			&-left {
				border-radius: 7px 7px 0 0; }
			&-right {
				border-radius: 0 0 7px 7px; } } }
	.system {
		&-item {
			padding: 20px 15px;
			&__title {
				font-size: 19px; }
			&__subtitle {
				font-size: 15px; } } }
	.about {
		&-img {
			margin-bottom: 20px;
			max-height: 460px;
			overflow: hidden; } }
	.method {
		&-utp {
			margin-bottom: 30px;
			padding: 20px;
			.mb-78 {
				margin-bottom: 28px; } } }
	.faq {
		&-question {
			font-size: 16px; }
		&-answer {
			font-size: 15.5px; } } }
@media(min-width: 992px) and (max-width: 1023px) {
	.quiz-img img {
		max-width: 190px; } }
@media(max-width: 991px) {
	.section-title {
		margin-bottom: 20px !important; }
	.section-subtitle {
		margin-bottom: 25px !important; }
	.header {
		background: #fff;
		&.scroll {}
		&-top {
			padding: 9.5px 0; }
		&-scroll {
			display: none; }
		&-mobile {
			background: #E31E26;
			padding: 15px;
			margin-left: -15px;
			margin-right: -15px; }
		.container {
			max-width: 100%; }
		&-phone {
			color: #fff; }
		&-loc {
			justify-content: center; } }
	.banner {
		flex-wrap: wrap;
		&-right {
			width: 100%;
			padding: 232px 15px 32px;
			background-position: 50% 0; }
		&-left {
			width: 100%;
			&-content {
				max-width: 100%;
				padding: 174px 15px 0; } }
		&-title {
			margin-bottom: 0; }
		&-print {
			transform: translateX(0);
			text-align: right;
			&::after {
				right: auto;
				left: 0;
				top: calc(50% - 127px); } } }
	.quiz {
		transform: translateX(0);
		flex-wrap: wrap;
		max-width: 500px;
		margin: 0 auto;
		&-text {
			border-radius: 5px 5px 0 0;
			width: 100%; }
		&-img {
			border-radius: 0 0 5px 5px;
			width: 100%; } }
	.lesson {
		padding-top: 40px; }
	.slider-nav {
		top: calc(50% - 9px);
		button {
			width: 10px;
			height: 17px;
			box-shadow: none;
			background-color: transparent; } }
	.tabs {
		margin-left: -5px;
		margin-right: -5px;
		flex-wrap: wrap;
		box-shadow: none; }
	.tab {
		margin: 5px;
		border-radius: 7px !important; }
	.courses {
		padding-top: 38px;
		padding-bottom: 47px; }
	.reviews {
		padding: 32px 0;
		position: relative;
		&-slider {
			padding-left: 15px;
			padding-right: 15px; }
		.slider-nav {
			top: 135px; }
		.slider-prev {
			left: -10px; }
		.slider-next {
			right: -10px; }
		.section-subtitle {
			display: none; }
		&::before {
			content: '';
			display: block;
			width: 129px;
			height: 157px;
			background: url('../img/reviews-achiev.svg') center no-repeat;
			position: absolute;
			top: 0;
			right: 0; } }
	.review {
		&-video {
			margin-bottom: 20px; } }
	.team {
		padding-top: 38px;
		padding-bottom: 55px;
		&-slider + .slider-nav {
			top: 100px; }
		.slider-prev {
			left: 25px; }
		.slider-next {
			right: 25px; }
		&-item {
			&__text {
				padding-right: 40px; } } }
	.system {
		padding-top: 35px;
		padding-bottom: 30px;
		&-item {
			margin-bottom: 26px; } }
	.about {
		padding-top: 36px;
		padding-bottom: 15px;
		.slider-prev {
			left: -5px; }
		.slider-next {
			right: -5px; }
		&-img {
			max-height: 350px; } }
	.gallery-slider {
		padding: 0 10px;
		margin-bottom: 25px;
		&__item {
			padding-left: 5px;
			padding-right: 5px; } }
	.method {
		padding-top: 41px;
		padding-bottom: 43px; }
	.faq {
		padding-top: 40px; }
	.contacts {
		padding-top: 50px;
		&-content {
			flex-wrap: wrap; }
		&-map {
			height: 500px; } } }
@media(max-width: 767px) {
	.lesson {
		&-head {
			flex-wrap: wrap;
			margin-bottom: 35px; }
		&-teacher {
			&__cta {
				display: none; } } }
	.course {
		&-price {
			flex-wrap: wrap;
			&__result {
				margin-left: 0;
				margin-top: 20px; } }
		&-links {
			flex-wrap: wrap;
			margin-bottom: 0;
			a {
				margin-bottom: 10px; } } }
	.read-more {
		max-height: 195px;
		height: 195px;
		overflow: hidden;
		transition: max-height .3s ease;
		&-open {
			max-height: 1000px;
			height: auto; } }
	.team {
		&-item {
			&-right {
				flex-wrap: wrap; }
			&__text {
				padding-right: 0; }
			&__certs {
				width: 100%; }
			.cert-slider {
				img {
					display: block;
					margin: 0 auto;
					left: 50%;
					transform: translateX(-50%);
					&.cert-active {
						transform: translateX(0); } } }
			.cert-slider {
				&-prev {
					left: -10px; }
				&-next {
					right: -10px; } } } }
	.footer {
		padding-top: 110px;
		padding-bottom: 20px;
		margin-top: -89px;
		&-item {
			text-align: center;
			margin-bottom: 10px; } } }
@media(max-width: 575px) {
	.section-title {
		font-size: 30px;
		line-height: 40px; }
	.banner {
		&-print {
			img {
				max-width: 150px; }
			&::after {
				width: 180px;
				height: 100%;
				background-size: contain;
				top: 0; } }
		&-undertitle {
			font-size: 15px; }
		&-title {
			font-size: 27px; } }
	.lesson {
		.section-title {
			flex-shrink: 1; } }
	.utp {
		&-item {
			text-align: center; } }
	.tab {
		// flex-grow: 0
		padding: 8px;
		font-size: 15px; }
	.course {
		&-title {
			font-size: 18px; } }
	.team {
		&-item {
			&__photo {
				img {
					max-width: 190px; } } } }
	.about {
		&-content {
			margin-bottom: 10px; } }
	.achievement {
		margin-bottom: 15px; }
	.method {
		&-utp {
			margin-bottom: 87px;
			&__item {
				text-align: center; }
			.owl-dots {
				position: absolute;
				bottom: -60px;
				left: 0;
				width: 100%;
				margin-top: 0; }
			.slider-prev {
				left: -5px; }
			.slider-next {
				right: -5px; } } }
	.contacts {
		&-content {
			padding: 35px 15px 15px; }
		&-items {
			padding-right: 0;
			flex-shrink: 1; }
		&-item {
			margin-bottom: 17px;
			&__icon {
				margin-right: 15px; }
			&__title {
				font-size: 17px; }
			&__value {
				font-size: 15px; } }
		&-map {
			height: 350px; } }
	.popup {
		padding: 35px 25px 25px;
		&-title {
			font-size: 20px !important; }
		&#quiz {
			min-height: 455px; } }
	.fancybox-close {
		top: 14px; } }
@media(max-width: 420px) {
	.banner {
		&-right {
			background-size: 590px; } }
	.team {
		&-item {
			&-left {
				padding: 27px; }
			&-right {
				padding: 20px; }
			&__achievements {
				&-descr {
					font-size: 16px; }
				&-icon {
					margin-right: 15px;
					min-width: auto;
					width: 28px;
					flex-shrink: 0;
					img {
						max-width: 100%; } } } } }
	.faq {
		&-form {
			padding: 25px 15px; } }
	.form {
		&-title {
			font-size: 18px; }
		&-subtitle {
			font-size: 15px;
			margin-bottom: 17px; }
		&-checkbox {
			&-label {
				text-align: center; } } }
	.popup {
		padding: 35px 20px 25px;
		&#quiz {
			.form-item {
				label {
					font-size: 14px !important; }
				input {
					margin-right: 5px; } } } } }
@media(max-width: 374px) {
	.section-title {
		font-size: 22px;
		line-height: 34px; }
	.btn-link {
		font-size: 15px; }
	.form {
		&-button {
			.btn {
				width: 100%;
				padding-left: 5px;
				padding-right: 5px; } } }
	.cert-slider {
		img {
			max-width: 200px; } }
	.faq {
		&-item {
			padding: 12px; }
		&-question {} }
	.fancybox-wrap {
		min-width: calc(100% - 10px) !important;
		left: 5px !important;
		.fancybox-inner {
			width: 100% !important; } }
	.popup {
		padding: 35px 20px 25px; } }
