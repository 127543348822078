.about {
	padding-top: 76px;
	padding-bottom: 30px;
	background: #D5D7D6;
	.section-title {
		margin-bottom: 55px; }
	&-content {
		margin-bottom: 70px; }
	&-img {
		img {
			width: 100%; } }
	&-text {
		font-weight: 300;
		font-size: 17px;
		line-height: 22px;
		color: #2E4878;
		margin-bottom: 18px;
		text-align: justify; } }
.achievement {
	text-align: center;
	&-num {
		font-family: Merriweather;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 50px;
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: #2E4878; }
	&-title {
		font-family: Merriweather;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: #2E4878; } }
.gallery {
	.section-title {
		margin-bottom: 25px;
		font-size: 25px; }
	&-slider {
		margin-bottom: 57px;
		&__item {
			padding-left: 13px;
			padding-right: 13px; } } }
