.reviews {
	padding-top: 78px;
	padding-bottom: 90px;
	&-title {
		margin-bottom: 28px; }
	.section-subtitle {
		margin-bottom: 65px; } }
.review {
	&-video {
		text-align: center;
		img {
			max-width: 100%; } }
	&-text {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		color: #2E4878;
		margin-bottom: 12px;
		span {
			font-weight: 500; }
		p {
			margin-bottom: 0; } } }
.slider .slider-item img {
	width: auto;
	display: inline; }
