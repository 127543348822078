.faq {
	background: #D5D7D6;
	padding-top: 64px;
	padding-bottom: 55px;
	.section-title {
		margin-bottom: 49px; }
	&-item {
		background: #FFF;
		box-shadow: 0px 0px 20px rgba(166, 166, 166, 0.3);
		border-radius: 7px;
		padding: 12px 35px;
		margin-bottom: 25px; }
	&-question {
		font-family: Merriweather;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: #2E4878;
		margin-bottom: 0px;
		position: relative;
		cursor: pointer;
		padding-right: 30px;
		&.toggle-open {
			margin-bottom: 15px; } }
	&-answer {
		font-weight: 300;
		font-size: 17px;
		color: #2E4878; }
	&-form {
		background: #FFF;
		box-shadow: 0px 0px 30px rgba(166, 166, 166, 0.35);
		border-radius: 7px;
		margin-bottom: 30px;
		padding: 50px 55px 39px; }
	&-img {
		text-align: center;
		img {
			max-width: 100%; } } }

