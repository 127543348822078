.tabs {
	display: flex;
	box-shadow: 0px 0px 20px rgba(166, 166, 166, 0.3); }
.tab {
	font-weight: normal;
	font-size: 18px;
	text-align: center;
	color: #2E4878;
	background: #F9F9F9;
	padding: 17.5px;
	flex-grow: 1;
	cursor: pointer;
	transition: background .2s ease;
	&:first-child {
		border-radius: 7px 0 0 7px; }
	&:last-child {
		border-radius: 0 7px 7px 0; }
	&:hover, {
		color: #fff;
		background: #DE232A; }
	&-active {
		color: #fff;
		background: #DE232A; }
	&-content {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		transition: all .5s ease;
		&-active {
			position: static;
			opacity: 1;
			visibility: visible; } } }
