.overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0,0,0,.5);
	opacity: 0;
	visibility: hidden;
	transition: all .2s ease;
	z-index: 18;
	&-active {
		opacity: 1;
		visibility: visible; } }
.header {
	&-toggler {
		width: 28px;
		height: 23px;
		position: relative;
		cursor: pointer;
		&-line {
			width: 100%;
			height: 3px;
			background: #FFF;
			position: absolute;
			left: 0;
			transition: all .3s ease;
			&-first {
				top: 0; }
			&-second {
				top: calc(50% - 1.5px);
				opacity: 1;
				visibility: visible; }
			&-third {
				bottom: 0; } }
		&-open {
			.header-toggler-line {
				top: calc(50% - 1.5px);
				&-first {
					transform: rotate(45deg); }
				&-second {
					opacity: 0;
					visibility: hidden; }
				&-third {
					transform: rotate(-45deg); } } } } }
.mobile {
	&-menu {
		width: 100%;
		background: #fff;
		padding: 21px 60px 32px;
		position: fixed;
		top: 137px;
		z-index: 50;
		transform: translateX(-100%);
		transition: transform .3s ease-out, top .2s ease;
		&-active {
			transform: translateX(0); } }
	&-nav {
		margin-bottom: 20px;
		&__item {
			font-weight: 500;
			font-size: 16px;
			text-align: center;
			text-transform: uppercase;
			color: #2E4878;
			list-style-type: none;
			padding: 11px 5px; } } }
.header.scroll + .mobile-menu {
	top: 55px; }
