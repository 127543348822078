.team {
	padding-top: 81px;
	padding-bottom: 90px;
	background: #D5D7D6;
	&-title {
		margin-bottom: 28px; }
	.section-subtitle {
		margin-bottom: 54px; }
	&-slider {}
	&-item {
		border-radius: 7px;
		&-left {
			background: #fff;
			border-radius: 7px 0 0 7px;
			text-align: center;
			padding: 31px 70px 42px;
			height: 100%; }
		&-right {
			background: #2D4889;
			border-radius: 0 7px 7px 0;
			display: flex;
			padding: 40px 100px 48px 50px;
			height: 100%; }
		&__photo {
			margin-bottom: 23px;
			img {
				max-width: 100%; } }
		&__name {
			font-family: Merriweather;
			font-weight: bold;
			font-size: 20px;
			color: #2E4878;
			margin-bottom: 30px;
			position: relative;
			&::after {
				content: '';
				display: block;
				width: 67px;
				height: 2px;
				position: absolute;
				left: calc(50% - 33.5px);
				bottom: -15px;
				background: #2E4878; } }
		&__cta {
			font-size: 17px;
			color: #2E4878;
			margin-bottom: 22px; }
		&__button {
			.btn {
				padding-left: 45px;
				padding-right: 45px; } }
		&__text {
			padding-right: 70px; }
		&__achievements {
			display: flex;
			margin-bottom: 25px;
			&-descr {
				font-weight: 300;
				font-size: 20px;
				color: #FFF;
				span {
					font-weight: 500; } }
			&-icon {
				margin-right: 30px;
				min-width: 40px;
				text-align: center; } }
		&__certs {
			flex-shrink: 0;
			&-title {
				font-weight: 500;
				font-size: 20px;
				color: #FFF;
				margin-bottom: 27px; } } } }
