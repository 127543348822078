.utp {
	padding-top: 64px;
	padding-bottom: 49px;
	&-title {
		font-size: 22px;
		line-height: 38px;
		margin-bottom: 34px; }
	&-item {
		&__icon {
			margin-bottom: 15px; }
		&__title {
			font-weight: 500;
			font-size: 17px;
			color: #2E4878; }
		&__text {
			font-weight: 300;
			font-size: 17px;
			color: #2E4878; } }
	&-slider {
		padding: 0 25px;
		.owl-item {
			img {
				display: inline;
				width: auto; } } }
	.slider-prev {
		left: 0; }
	.slider-next {
		right: 0; } }
