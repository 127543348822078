.header {
	position: fixed;
	width: 100%;
	z-index: 20;
	background: transparent;
	transition: transform .2s ease;
	&-top {
		padding-top: 19px; }
	&-scroll {
		padding: 8px 0;
		opacity: 0;
		visibility: hidden;
		transition: all .2s ease; }
	&-logo {
		text-align: center; }
	&-button {
		.btn {
			font-size: 14px;
			display: block;
			text-align: center;
			padding: 7px 5px; } }
	&-title {
		font-size: 14px;
		color: #2E4878; }
	&-loc {
		display: flex;
		&__icon {
			margin-right: 10px;
			flex-shrink: 0;
			margin-top: -3px; }
		&__text {
			font-size: 15px;
			line-height: 19px;
			color: #2E4878;
			p {
				margin-bottom: 5px; }
			a {
				border-bottom: 1px dashed #2e4878;
				&:hover {
					text-decoration: none; } } } }
	&-info {
		display: flex;
		justify-content: center;
		&__icon {
			margin-right: 10px; } }
	&-phone {
		font-weight: 500;
		font-size: 18px;
		color: #2E4878; }
	&-time {
		font-size: 15px;
		color: #2E4878; }
	&-nav {
		&__list {
			margin-right: -15px;
			margin-left: -15px; }
		&__item {
			display: inline-block;
			font-weight: 500;
			font-size: 13px;
			text-transform: uppercase;
			color: #2E4878;
			margin: 0 15px;
			border-bottom: 2px solid transparent;
			transition: all .3s ease;
			a {
				&:hover {
					text-decoration: none; } }
			&:hover {
				border-bottom: 2px solid #2E4878; } } }
	&.scroll {
		background: #fff;
		transform: translateY(-68px);
		box-shadow: 0px 0px 10px rgba(166, 166, 166, 0.3);
		.header-scroll {
			opacity: 1;
			visibility: visible; } } }
